import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import FurtherReading from "../components/furtherReading"

const HTMLSetup = () => (
  <Layout>
    <SEO
      title="Setting up Your HTML Website Using cPanel"
      description="An easy guide on how to upload your HTML website into a subdirectory through cPanel. Answering all your questions on uploading a HTML website along the way."
    />
    <h1>Setting up Your HTML Website Using cPanel</h1>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/HPPDRnM.png"
        alt="campbell data science cpanel logo"
        description="cpanel logo for campbell data science guide for uploading html code"
      />
    </div>

    <p>
      If you don’t have hosting yet and don’t know which host to go with, you
      can read my{" "}
      <Link to="/siteground-review/" style={{ textDecoration: "none" }}>
        SiteGround review
      </Link>
      . Come back over to this page once you’re all set up.
    </p>
    <p>Here is what I use for my website setup;</p>
    <p>
      <a
        href="https://www.siteground.co.uk/index.htm?afcode=5c0d3ef0752592ef0a6eee476d89949b"
        target="_blank"
        rel="noopener noreferrer nofollow"
        style={{ textDecoration: "none" }}
      >
        SiteGround
      </a>{" "}
      for domain hosting
    </p>
    <p>
      <a
        href="http://www.anrdoezrs.net/click-9120749-12892698"
        target="_blank"
        rel="noopener noreferrer nofollow"
        style={{ textDecoration: "none" }}
      >
        Namecheap
      </a>{" "}
      for domain name registration
    </p>
    <p>
      Before we get started, let’s go over some common questions that will help
      you understand what is happening here. If you want to get started right
      away, just skip ahead.
    </p>
    <p>
      <i>
        Some of the links in this article may be affiliate links, which means I
        may receive a small commission at no additional cost to you if you
        decide to purchase something.
      </i>
    </p>
    <h2>What is cPanel?</h2>
    <p>
      cPanel is a tool that makes web hosting more accessible. It takes some of
      the complexity out of web development. Through cPanel you get access to
      things like file browsers, databases and quick WordPress installation. All
      of this is presented as a graphical user interface, meaning you don’t have
      to type out code overtime you want to make some changes to your site.
    </p>
    <p>
      cPanel is used by multiple web hosting platforms, not just SiteGround.
    </p>
    <h2>How to upload your site to the web?</h2>
    <p>
      There many ways to do this, let’s look at a few. One is to use a FTP (File
      Transfer Protocol). It involves using a client to publish your site. We
      realise this can be complicated and we like to keep things simple for our
      clients.
    </p>
    <p>
      Another way is to manually upload the site using the file browser through
      cPanel. We will use this method below to upload your HTML file and more.
    </p>
    <p>
      Lastly, you could install WordPress and build a your site using their
      tools. We recommend a combination of WordPress and coding your own pages.
      This is because WordPress has some great blogging tools and coding your
      own pages gives you the freedom to get really, really creative.
    </p>
    <p>
      As a data scientist, it lets me create pages that use{" "}
      <a
        href="https://github.com/d3/d3/wiki/Gallery"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        D3.js for complex visualisations
      </a>{" "}
      and{" "}
      <a
        href="https://www.chartjs.org/samples/latest/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Chart.js for more simple visualisations
      </a>
      . As a marketer, it allows me to create some amazing landing pages, single
      page sites and complete websites.
    </p>
    <h2>What is a Static Website?</h2>
    <p>
      A static site sounds much worse than it is. It is simply a site with no
      backend or server side code. It means, once you load the web page, all the
      functionality comes with it. You won’t be able to communicate with a
      server. Static sites are usually built with HTML, CSS and JavaScript.
    </p>
    <p>
      The opposite to a static site is usually called a web application. Amazing
      websites have been built with both methods, whichever you choose depends
      on your needs.
    </p>
    <h2>What is a dynamic website?</h2>
    <p>
      A dynamic or responsive site is one that renders clearly on all standard
      display sizes. A page will look good on your phone screen and on your
      desktop computer screen.
    </p>
    <p>
      Some companies with large budgets like Facebook actually code two separate
      websites. You can tell the{" "}
      <a
        href="https://www.facebook.com/help/169118286483273?helpref=uf_permalink"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        mobile version
      </a>{" "}
      by the “m” in the URL the browser displays.
    </p>
    <p>
      Google makes it clear that it penalises sites that aren’t responsive. It
      is all about user experience and your site should display properly on all
      platforms. Luckily, we know how to do this with HTML and CSS. WordPress
      templates are also built with this in mind so you don’t have to worry
      about it.
    </p>
    <h2>What is the .htaccess file?</h2>
    <p>
      We will go over this one a bit later on. As an outline, the hypertext
      access file is a configuration file used by several web servers. It
      handles URL redirection, URL shortening, access control and much more.
    </p>
    <p>
      We don’t need to go too in depth with this, just remember that it
      important for how the browser displays the URL for your web page. Also the
      ‘dot’ before the file name makes it a hidden file, we will reveal hidden
      files so don’t worry about this.
    </p>
    <h2>What is a URL?</h2>
    <p>
      A quick one, this is what is displayed in the web browser address bar.
    </p>
    <h2>What is the difference between http and https?</h2>
    <p>
      This is about security. Website owners can request an SSL certificate. It
      tells users that the site is secure and they can rest assured that they
      are protected against basic threats. This will not protect you against all
      threats so as usual, browse responsibly.
    </p>
    <p>
      To be clear https is the secure one, if you use google chrome it will tell
      you. Don’t be completely put off sites that don’t have https although it
      is a sign that the developer wants to keep the user happy if they do have
      it.
    </p>
    <p>
      If you have any more questions that will help you and others, let me know
      so I can update this page. Let’s get started….
    </p>
    <h2>Uploading Your HTML Site Using cPanel</h2>
    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/GjeS8dP.png"
        alt="campbell data science html code"
        description="campbell data science html code"
      />
    </div>
    <p>log in to your site ground account.</p>
    <p>go to the "my accounts" tab.</p>
    <p>Select "Go to cPanel".</p>
    <p>
      Scroll down to file manager and click it to open the file manager
      directory selection.
    </p>
    <p>
      Select the document root for your site from the drop down and tick show
      hidden files.
    </p>
    <h2>Uploading Your Website</h2>
    <p>
      Make sure you are in public_html then the folder for your website. You
      will probably already have some folders in here.
    </p>
    <p>
      Create a new folder. Give it the name you want the URL to be. e.g. if you
      want your page to be “example.com/test” then give the folder the name
      “test”.
    </p>
    <p>
      Select upload and tick all 3 boxes for execute. This will give you a
      permission code of 755 for each file. Then choose each file and upload it.
    </p>
    <p>
      If you have files within folders, create the folder then upload the files
      into their respective folders. You want to replicate the folder structure
      you have on your computer within the file manager.
    </p>
    <p>
      <i>
        For larger folder structures you would upload a zip file and extract.
        Let’s keep it simple for now and not do this.
      </i>
    </p>
    <p>
      Once you are done, click “go back” and make sure you have uploaded each
      file. Compare the cPanel browser to the one on your computer.
    </p>
    <h2>Accessing Your Files</h2>
    <p>
      If you try to access any html file now, you will find that the browser
      displays the url with the file extension. e.g. “example.com/test.html”… I
      don’t like this so here is what I do to fix it or get a cleaner URL
      “example.com/test”;
    </p>
    <p>
      <i>
        As a note, I use index.html for the file I want the browser to read
        first when I access the folder. I believe this is industry standard.
      </i>
    </p>
    <p>
      for every folder with a html page the browser will display, create a new
      file called “.htaccess”. In the file I use the following code. As I
      described above, it tells the browser to display the page URL in a
      specific way.
    </p>
    <p>
      Right click the new .htaccess file and click edit. The character encoding
      should be set to “uft-8”. Leave it and click edit.
    </p>
    <p>Paste the following code and click save then close.</p>
    <p>*****</p>
    {/* Use <pre></pre> for a block of code, <code></code> for a piece */}
    <pre>
      {`
RewriteEngine On
RewriteCond %{REQUEST_FILENAME} !-f
RewriteRule ^([^.]+)$ $1.html [NC,L]

RewriteEngine on
RewriteCond %{THE_REQUEST} /([^.]+)\.html [NC]
RewriteRule ^ /%1 [NC,L,R]

RewriteCond %{REQUEST_FILENAME}.html -f
RewriteRule ^ %{REQUEST_URI}.html [NC,L]
          `}
    </pre>
    <p>*****</p>
    <h2>Good Job!</h2>
    <p>
      If all has gone to plan, you will be able to type your website URL
      followed by the file directory to access your web page.
    </p>
    <p>
      I hope I have helped you out with this one. If you would prefer a more in
      depth guide with images or even a video just let me know. If you would
      rather not do this yourself and want some help setting up, get in touch.
      It’s what we do.
    </p>
    <p>
      As a note, there are many ways to do the same thing in web development.
      This is the way I do it with static pages and just happens to also be one
      of the easiest I have come across.
    </p>

    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default HTMLSetup
